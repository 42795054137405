.add-product{
    .image-placeholder{
        .avatar-preview{
            height: 14rem !important;
        }
        .delete-img{
            position: absolute;
            top: 16px;
            right: 16px;
            padding: 2px 6px;
            z-index: 1;
            background: #fff;
            border: 0;
            border-radius: 50%;
            .la{
                color: var(--primary);
                font-size: 18px;
            }
        }
        img{
            border-radius: 30px;
        }
    }
}
.address-model{
    p{
        color: #000;
        margin: 0;
    }
    label{
        color: #7E7E7E;
        margin: 0;
        width: 50%;
    }
}