.home{
.banners{
    .image-placeholder{
        max-width: 100%;
        margin: 0;
    }
    .avatar-preview.banner{
        height: 200px !important;
    }
    .avatar-preview{
        height: 25rem;
        width: 100% !important;
    }
    
}

.branding-description{
    .avatar-preview{
        height: 14rem !important;
    }
}
.our-clients{
    .image-placeholder{
        margin: 0;
        .avatar-preview{
            height: 9rem !important;
        }
    }
}
.add-client{
    text-align: center;
    i{
        font-size: 50px;
        background: #dedede;
        color: #fff;
        border-radius: 50%;
        padding: 10px;
    }
}
.delete-img{
    position: absolute;
    top: -13px;
    right: 16px;
    padding: 2px 6px;
    z-index: 1;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 50%;
    .la{
        color: var(--primary);
        font-size: 18px;
    }
}
.h-25rem{
    height: 25rem !important;
}

.editorClassName{
    padding: 12px !important;
    // border: 1px solid #dedede;
    border-radius: 8px;
}
.wrapperClassName {
    height: auto;
}
}
.rdw-editor-main{
    height: auto!important;
}