.add-variant{
    .input-group{
        .form-control{
            gap: 8px;
            min-height: 44px !important;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }
    .badge{
        font-size: 14px;
        font-weight: 600;
        height: fit-content;
        background-color: #dedede !important;
        border-radius: 6px;

        button{
            margin-left: 8px;
            color: red;
        }
    }
    .generate{
        padding: 8px 16px;
        border-radius: 0 8px 8px 0;
        background-color: #5bc0de !important;
        color: #fff !important;
    }
}